import React from "react";
import { Button, Calendar } from "components/ui";
import classNames from "classnames";

const CalendarSelection = ({ data, setData, onDateSelect, setStep, disableCertainDate }) => {

  const isToday = (someDate) => {
    const today = new Date();
    return (
      someDate.getDate() === today.getDate() &&
      someDate.getMonth() === today.getMonth() &&
      someDate.getFullYear() === today.getFullYear()
    );
  };

  const handleDateChange = (d) => {
    setData(d);
    onDateSelect(d);
    setStep(3);
  };

  return (
    <div className="flex flex-col gap-4 self-center w-[300px]">
      <h3 className="text-indigo-800 font-bold">Escolha uma data</h3>
      <Calendar
        value={data}
        disableDate={disableCertainDate}
        locale="pt-br"
        onChange={handleDateChange}
        dayClassName={(date, { selected }) => {
          const defaultClass = "text-base";
          if (isToday(date) && !selected) {
            return classNames(defaultClass, "text-indigo-800");
          }
          if (selected) {
            return classNames(defaultClass, "text-white");
          }
          return defaultClass;
        }}
        dayStyle={() => {
          return { height: 48 };
        }}
      />
      <Button
        className="my-4 cursor-pointer border transition-colors hover:border-[#3730a3]"
        onClick={() => setStep(1)}
      >
        Voltar
      </Button>
    </div>
  );
};

export default CalendarSelection;
