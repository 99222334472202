import React, { useState } from "react";
import { Input, Button } from "components/ui";
import InputMask from "react-input-mask";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";

const ClientDataForm = ({
  nome,
  setNome,
  cpf,
  setCpf,
  telefone,
  setTelefone,
  email,
  setEmail,
  motivo,
  setMotivo,
  dataNascimento,
  setDataNascimento,
  sending,
  checarSeClienteTerapeutaClick,
  agendarClick,
}) => {
  const [isClienteConhecido, setIsClienteConhecido] = useState(false);

  const handleContinuarClick = async () => {
    const resultado = await checarSeClienteTerapeutaClick();
    if (!resultado) {
      setIsClienteConhecido(true);
    }
  };

  return (
    <div className="flex flex-col gap-4 w-[300px]">
      {!isClienteConhecido ? (
        <div className="flex flex-col gap-4 w-[300px]">
          <div className="flex-col">
            <h3 className="text-indigo-800 font-bold">Informe os dados</h3>

            <PhoneInput
              inputStyle={{
                fontSize: "16px",
                color: "gray",
                border: "none",
              }}
              countrySelectorStyleProps={{
                buttonStyle: { border: "none" },
              }}
              className="input mb-4"
              defaultCountry="br"
              value={telefone}
              name="telefone"
              onChange={(v) => setTelefone(v)}
            />
            <Input
              maxlength="150"
              className="my-4"
              size=""
              value={motivo}
              placeholder="Qual o motivo do atendimento?"
              onChange={(v) => setMotivo(v.target.value)}
            />
            <Button
              variant="solid"
              className="bg-indigo-800 text-white hover:bg-indigo-900"
              size="lg"
              onClick={handleContinuarClick}
            >
              Continuar
            </Button>
          </div>
        </div>
      ) : (
        <div className="flex flex-col gap-4 w-[300px]">
          <div className="flex-col">
            <h4>Informe seus Dados</h4>
            <Input
              maxlength="100"
              className="my-4"
              size=""
              value={nome}
              placeholder="Nome"
              onChange={(v) => setNome(v.target.value)}
            />
            <Input
              maxlength="150"
              className="my-4"
              size=""
              value={motivo}
              placeholder="Qual o motivo do atendimento?"
              onChange={(v) => setMotivo(v.target.value)}
            />
            <InputMask
              mask="99999999999"
              maskChar=""
              placeholder="CPF"
              className="input my-4"
              onChange={(v) => setCpf(v.target.value.replaceAll("_", ""))}
              value={cpf}
            />

            <PhoneInput
              inputStyle={{
                fontSize: "16px",
                color: "gray",
                border: "none",
              }}
              countrySelectorStyleProps={{
                buttonStyle: { border: "none" },
              }}
              className="input"
              defaultCountry="br"
              value={telefone}
              name="telefone"
              onChange={(v) => setTelefone(v)}
            />
            <InputMask
              mask="99/99/9999"
              maskChar=""
              placeholder="Data de Nascimento"
              className="input my-4"
              onChange={(v) =>
                setDataNascimento(v.target.value.replaceAll("_", ""))
              }
              value={dataNascimento}
            />
            <Input
              maxlength="150"
              className="my-4"
              size=""
              value={email}
              placeholder="Email"
              onChange={(v) => setEmail(v.target.value)}
            />
            <Button
              loading={sending}
              className="bg-indigo-800 text-white hover:bg-indigo-900"
              variant="solid"
              size="lg"
              onClick={agendarClick}
            >
              {sending ? "Agendando..." : "Agendar Horário"}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ClientDataForm;
