import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const Header = ({ urlFoto, nome, profissao }) => {
  return (
    <div className="flex flex-col self-center p-4">
      <img
        className="drop-shadow-md self-center mt-4 rounded-full"
        src={urlFoto ? urlFoto : "./img/avatars/thumb-10.jpg"}
        width="180"
        alt={`${nome}'s profile`}
      />
      <p className="text-lg font-bold text-indigo-800 self-center text-center">
        {nome}
      </p>
      <p className="text-center">{profissao}</p>
    </div>
  );
};

Header.propTypes = {
  urlFoto: PropTypes.string,
  nome: PropTypes.string.isRequired,
  profissao: PropTypes.string.isRequired,
};

export default Header;
