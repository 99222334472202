import React, { useState, useEffect, useRef } from "react";
import { Button, Card, Calendar, Badge } from "components/ui";
import classNames from "classnames";
import useThemeClass from "utils/hooks/useThemeClass";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { toast, ToastContainer } from "react-toastify";
import { useParams } from "react-router-dom";
import useClientData from "utils/hooks/useClientData";
import useAgendamentoData from "utils/hooks/useAgendamentoData ";

import Header from "./components/Header";
import StepInitial from "./StepInitial ";
import LocalidadeSelection from "./LocalidadeSelection";
import CalendarSelection from "./CalendarSelection";
import ClientDataForm from "./ClientDataForm";
import FusoMessage from "./FusoMessage";
import ServicoSelection from "./ServicoSelection ";
import HorarioSelection from "./HorarioSelection";
import ReservaConfirmacao from "./ReservaConfirmacao";
import AgendamentoRealizado from "./AgendamentoRealizado";

dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(timezone);

const CalendarioPublico = () => {
  let { idCliente } = useParams();
  const [step, setStep] = useState(-1);
  const [agendaSelecionadaId, setAgendaSelecionadaId] = useState();
  const [servicoEscolhido, setServicoEscolhido] = useState(null);
  const [localidadeEscolhida, setLocalidadeEscolhida] = useState(null);
  const [reservaRedirectUrl, setReservaRedirectUrl] = useState(null);
  const timezonePaciente = useRef();


  const {
    dadosTerapeuta,
    servicosLocalidades,
    localidadesUnicas,
    dados,
    data,
    setData,
    horaUsuario,
    setHoraUsuario,
    horaProfissional,
    setHoraProfissional,
    agendaDisponivel,
    liberarAgenda,
    isMensagemFusoOpen,
    setIsMensagemFusoOpen,
    loadAgendamentoData,
    onDateSelect,
    disableCertainDate,
    links,
  } = useAgendamentoData(idCliente, timezonePaciente);

  const {
    nome,
    setNome,
    cpf,
    setCpf,
    telefone,
    setTelefone,
    email,
    setEmail,
    motivo,
    setMotivo,
    dataNascimento,
    setDataNascimento,
    sending,
    setSending,
    checarSeClienteTerapeutaClick,
    agendarClick,
  } = useClientData(
    idCliente,
    dadosTerapeuta,
    agendaSelecionadaId,
    horaProfissional,
    timezonePaciente,
    setStep,
    servicoEscolhido,
    setReservaRedirectUrl,
  );

  useEffect(() => {
    loadAgendamentoData();
  }, [idCliente]);

  const escolherLocalidadeClick = (localidade) => {
    setLocalidadeEscolhida(localidade);
    setStep(1);
  };

  const escolherServicoClick = (servico) => {
    setServicoEscolhido(servico);
    setStep(2);
  };


  return (
    <>
      <ToastContainer />
      <FusoMessage
        isOpen={isMensagemFusoOpen}
        onClose={() => setIsMensagemFusoOpen(false)}
      />
      <div className={classNames("flex flex-col justify-center")}>
        <div className="flex flex-col text-center">
          <Header
            urlFoto={dadosTerapeuta.urlFoto}
            nome={dadosTerapeuta.nome}
            profissao={dadosTerapeuta.profissao}
          />

          {step === -1 && (
            <StepInitial
              agendaDisponivel={agendaDisponivel}
              liberarAgenda={liberarAgenda}
              setStep={setStep}
              dadosTerapeuta={dadosTerapeuta}
              links={links}
            />
          )}

          {step === 0 && (
            <LocalidadeSelection
              localidadesUnicas={localidadesUnicas}
              setStep={setStep}
              agendaDisponivel={agendaDisponivel}
              liberarAgenda={liberarAgenda}
              dadosTerapeuta={dadosTerapeuta}
              escolherLocalidadeClick={escolherLocalidadeClick}
            />
          )}

          {step === 1 && (
            <ServicoSelection
              servicosLocalidades={servicosLocalidades}
              localidadeEscolhida={localidadeEscolhida}
              setStep={setStep}
              escolherServicoClick={escolherServicoClick}
            />
          )}

          {step === 2 && (
            <CalendarSelection
              data={data}
              setData={setData}
              onDateSelect={onDateSelect}
              setStep={setStep}
              disableCertainDate={disableCertainDate}
            />
          )}

          {step === 3 && (
            <HorarioSelection
              data={data}
              dadosTerapeuta={dadosTerapeuta}
              servicoEscolhido={servicoEscolhido}
              localidadeEscolhida={localidadeEscolhida}
              setHoraUsuario={setHoraUsuario}
              setHoraProfissional={setHoraProfissional}
              setAgendaSelecionadaId={setAgendaSelecionadaId}
              setStep={setStep}
              timezonePaciente={timezonePaciente}
              dados={dados}
            />
          )}

          {step === 4 && (
            <ClientDataForm
              nome={nome}
              setNome={setNome}
              cpf={cpf}
              setCpf={setCpf}
              telefone={telefone}
              setTelefone={setTelefone}
              email={email}
              setEmail={setEmail}
              motivo={motivo}
              setMotivo={setMotivo}
              dataNascimento={dataNascimento}
              setDataNascimento={setDataNascimento}
              sending={sending}
              checarSeClienteTerapeutaClick={checarSeClienteTerapeutaClick}
              agendarClick={agendarClick}
              setStep={setStep}
              step={step}
            />
          )}

          {step === 6 && (
            <AgendamentoRealizado
              data={data}
              horaUsuario={horaUsuario}
              servicoEscolhido={servicoEscolhido}
              localidadeEscolhida={localidadeEscolhida}
            />
          )}

          {step === 7 && (
            <ReservaConfirmacao
              profissional={dadosTerapeuta.nome}
              idCliente={idCliente}
              local={localidadeEscolhida.nome}
              servico={servicoEscolhido.nome}
              horario={horaUsuario}
              redirectUrl={reservaRedirectUrl}
              data={data}

            />
          )}
        </div>

        <div className="flex flex-row self-center my-8">
          <span>Powered by </span>
          <a
            href="https://www.usecorpora.com.br"
            className="text-indigo-800 font-bold"
          >
            <img src="/img/logo/logo-light-full.png" width="120" />
          </a>
        </div>
      </div>
    </>
  );
};

export default CalendarioPublico;
