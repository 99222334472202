import React, { useEffect, useState } from 'react';
import { _confirmarAgenda } from 'services/AuthService';
import { useParams } from "react-router-dom";
import { Button, Card } from 'components/ui'

const ConfirmacaoAgenda = () => {
    let { uuidAgenda } = useParams();
    const [agendaExistente, setAgendaExistente] = useState(false);

    const confirmar = async () => {
        try {
            await _confirmarAgenda(uuidAgenda);
            setAgendaExistente(true);
        }
        catch (err) {
            setAgendaExistente(false);
        }
    }

    useEffect(() => {
        confirmar();
    }, []);

    if (agendaExistente)
    return (
        <Card className="m-4">
            <h5 className="font-semi-bold mb-4">Corpora</h5>
            <h6 className="font-light">Agenda confirmada com sucesso!</h6>
        </Card>
    )
    else {
        return (
            <Card className="m-4">
                <h5 className="font-semi-bold mb-4">Corpora</h5>
                <h6 className="font-light">Desculpe, mas não foi possível confirmar a agenda. Por favor procure informações com o seu profissional.</h6>
            </Card>
        )
    }
}

export default ConfirmacaoAgenda;;