import React from "react";
import PropTypes from "prop-types";
import ContactLinks from "./ContactLinks";

const StepInitial = ({ agendaDisponivel, liberarAgenda, setStep, dadosTerapeuta, links }) => {
  return (
    <div className="flex flex-col">
      <div className="flex flex-col w-64">
        <div className="">
          {agendaDisponivel && liberarAgenda && (
            <a onClick={() => setStep(0)}>
              <p className="text-lg p-2 mb-4 rounded shadow-md text-grey-900 font-bold border transition-colors hover:border-[#3730a3] cursor-pointer">
                Agende agora
              </p>
            </a>
          )}

          <ContactLinks dadosTerapeuta={dadosTerapeuta} links={links} />
        </div>
      </div>
    </div>
  );
};

StepInitial.propTypes = {
  agendaDisponivel: PropTypes.bool.isRequired,
  liberarAgenda: PropTypes.bool.isRequired,
  setStep: PropTypes.func.isRequired,
  dadosTerapeuta: PropTypes.object.isRequired,
  links: PropTypes.array.isRequired,
};

export default StepInitial;
