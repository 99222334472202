import { useState, useRef } from "react";
import dayjs from "dayjs";
import { _buscarAgendaPublica } from "services/AgendaPublicaService";
import { toast } from "react-toastify";

const useAgendamentoData = (idCliente, timezonePaciente) => {
  const [dadosTerapeuta, setDadosTerapeuta] = useState({});
  const [servicosLocalidades, setServicosLocalidades] = useState({});
  const [localidadesUnicas, setLocalidadesUnicas] = useState({});
  const [dados, setDados] = useState([]);
  const [data, setData] = useState("");
  const [horaUsuario, setHoraUsuario] = useState("");
  const [horaProfissional, setHoraProfissional] = useState("");
  const [agendaDisponivel, setAgendaDisponivel] = useState(false);
  const [liberarAgenda, setLiberarAgenda] = useState(true);
  const [isMensagemFusoOpen, setIsMensagemFusoOpen] = useState(false);
  const [links, setLinks] = useState({});

  const loadAgendamentoData = async () => {
    try {
      const response = await _buscarAgendaPublica(idCliente);
      setLiberarAgenda(response.data.liberarAgenda);

      if (response.data.servicos.length > 0 && response.data.localidades.length > 0) {
        setAgendaDisponivel(true);
      }

      const parsedHorarios = parseAndCleanHorarios(response.data.horarios);
      removerAgendaNaoAtendeAntecedencia(parsedHorarios);
      setDados(parsedHorarios);

      const { servicos, localidades } = organizeServicosLocalidades(response.data);
      setServicosLocalidades(servicos);
      setLocalidadesUnicas(localidades);

      const tz = dayjs.tz.guess();
      timezonePaciente.current = tz || response.data.timezone || "America/Sao_Paulo";

      setDadosTerapeuta({
        id: response.data.id,
        nome: response.data.nome,
        email: response.data.email,
        telefone: response.data.telefone,
        profissao: response.data.profissao,
        urlFoto: response.data.urlFoto,
        timezone: response.data.timezone || timezonePaciente.current,
      });

      setLinks(JSON.parse(response.data.links));
      setData(dayjs(response.data.horarios[0].dia).format("YYYY-MM-DD"));
    } catch (error) {
      toast.error("Erro ao carregar os dados de agendamento.");
    }
  };

  const parseAndCleanHorarios = (agendas) => {
    return agendas.map((agenda) => ({
      ...agenda,
      horarios: JSON.parse(agenda.horarios),
    }));
  };

  const removerAgendaNaoAtendeAntecedencia = (agendas) => {
    agendas.forEach((agenda) => {
      agenda.horarios.forEach((horario) => {
        const diaHora = dayjs(`${agenda.dia} ${horario.h}`, "YYYY-MM-DD H:mm");
        if (diaHora.isBefore(dayjs().add(agenda.antecedencia, "hour"))) {
          horario.estado = "INDISPONIVEL";
        }
      });
    });
  };

  const organizeServicosLocalidades = (data) => {
    let servicos = {};
    let localidades = {};

    data.horarios.forEach((item) => {
      const key = `l${item.localidadeServicoId}s${item.servicoId}`;
      if (!servicos[key]) {
        servicos[key] = {
          localidade: data.localidades.find((vl) => vl.id === item.localidadeServicoId),
          servico: data.servicos.find((vl) => vl.id === item.servicoId),
        };
      }

      if (!localidades[`l${item.localidadeServicoId}`]) {
        localidades[`l${item.localidadeServicoId}`] = data.localidades.find(
          (vl) => vl.id === item.localidadeServicoId
        );
      }
    });

    return { servicos, localidades };
  };

  const onDateSelect = (date) => {
    setData(dayjs(date).format("YYYY-MM-DD"));
    if (timezonePaciente.current !== dadosTerapeuta.timezone) {
      setIsMensagemFusoOpen(true);
    }
  };

  const escolhaHoraClick = (horaUsuario, horaProfissional, agendaId) => {
    setHoraUsuario(horaUsuario);
    setHoraProfissional(horaProfissional);
  };

  const disableCertainDate = (date) => {
    const formattedDate = dayjs(date).format("YYYY-MM-DD");
    return !dados.some((vl) => {
      return (
        vl.dia === formattedDate &&
        vl.horarios.some((h) => h.estado !== "INDISPONIVEL")
      );
    });
  };

  return {
    dadosTerapeuta,
    servicosLocalidades,
    localidadesUnicas,
    dados,
    data,
    setData,
    horaUsuario,
    setHoraUsuario,
    horaProfissional,
    setHoraProfissional,
    agendaDisponivel,
    liberarAgenda,
    isMensagemFusoOpen,
    setIsMensagemFusoOpen,
    loadAgendamentoData,
    onDateSelect,
    escolhaHoraClick,
    disableCertainDate,
    links
  };
};

export default useAgendamentoData;
